var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"title":_vm.modalTitle,"persistent":""},on:{"created":_vm.onCreated,"on-enter":_vm.handleCreate,"hide":_vm.onModalClose,"show":_vm.onModalShow},scopedSlots:_vm._u([(_vm.errorMessage)?{key:"alert",fn:function(){return [_c('SlAlert',{attrs:{"type":"error","accent":_vm.errorMessage}})]},proxy:true}:null,{key:"footer",fn:function(){return [_c('SlModalFooter',[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleCreate}},[_vm._v(" "+_vm._s(_vm.$t('Web.InTransition.BtnCreate'))+" ")])],1)]},proxy:true}],null,true)},[_c('ValidationObserver',{ref:"observer",staticClass:"modal-content create-order"},[_c('SlValidate',{staticClass:"create-order__item",attrs:{"rules":"required","vid":"itemCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlSelect',{attrs:{"value":_vm.newItem.itemcode,"select-label":_vm.$t('Main.Cols.ItemCode'),"options":_vm.itemCodes,"loading":_vm.isLoading,"internal-search":false,"is-invalid":invalid,"searchable":"","required":""},on:{"search-change":function (val) { return _vm.handleSearch(val, _vm.inTransitionEntities.ITEM); },"input":function (val) { return _vm.handleSelectChange(val, _vm.inTransitionEntities.ITEM); }}})]}}])}),(_vm.hasLocation)?_c('SlValidate',{staticClass:"create-order__item",attrs:{"rules":{
        required: !_vm.isLocationDisabled
      },"vid":"location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.locationTooltip),expression:"locationTooltip",modifiers:{"bottom":true}}]},[_c('SlSelect',{attrs:{"value":_vm.newItem.location,"select-label":_vm.$t('Main.Cols.Location'),"options":_vm.locations,"loading":_vm.isLoading,"internal-search":false,"is-invalid":invalid,"disabled":_vm.isLocationDisabled,"required":"","searchable":""},on:{"search-change":function (val) { return _vm.handleSearch(val, _vm.inTransitionEntities.LOCATION); },"input":function (val) { return _vm.handleSelectChange(val, _vm.inTransitionEntities.LOCATION); }}})],1)]}}],null,false,44038867)}):_vm._e(),(!_vm.isArrival && _vm.hasChannel)?_c('div',{staticClass:"create-order__item"},[_c('SlSelect',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.channelTooltip),expression:"channelTooltip",modifiers:{"bottom":true}}],attrs:{"value":_vm.newItem.channel,"select-label":_vm.$t('Main.Cols.Channel'),"options":_vm.channels,"loading":_vm.isLoading,"internal-search":false,"disabled":_vm.isChannelDisabled,"searchable":""},on:{"search-change":function (val) { return _vm.handleSearch(val, _vm.inTransitionEntities.CHANNEL); },"input":function (val) { return _vm.handleSelectChange(val, _vm.inTransitionEntities.CHANNEL); }}})],1):_vm._e(),_c('SlValidate',{staticClass:"create-order__item",attrs:{"rules":"required|max_value:9999999999","vid":"qty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Main.Cols.OrderBy0'),"type":"number","is-invalid":invalid,"required":""},model:{value:(_vm.newItem.qty),callback:function ($$v) {_vm.$set(_vm.newItem, "qty", $$v)},expression:"newItem.qty"}})]}}])}),(_vm.isArrival)?_c('SlValidate',{staticClass:"create-order__item",attrs:{"rules":"sl_valid_date|sl_date_between","vid":"arrivalDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlDatePicker',{attrs:{"label":_vm.$t('PlannedOrders.Col.ArrivalDate'),"is-invalid":invalid},model:{value:(_vm.newItem.arrivalDate),callback:function ($$v) {_vm.$set(_vm.newItem, "arrivalDate", $$v)},expression:"newItem.arrivalDate"}})]}}],null,false,1977444145)}):_vm._e(),(_vm.isArrival)?_c('SlSelect',{staticClass:"create-order__item",attrs:{"select-label":_vm.$t('Main.Cols.OrderType'),"options":_vm.orderTypeOptions,"track-by":"value","label":"text","allow-empty":"","clearable":""},on:{"input":function (val) { return _vm.handleSelectChange(val, 'orderType'); }},model:{value:(_vm.newItem.orderType),callback:function ($$v) {_vm.$set(_vm.newItem, "orderType", $$v)},expression:"newItem.orderType"}}):_c('SlValidate',{staticClass:"create-order__item",attrs:{"rules":"sl_valid_date|sl_date_between","vid":"shipmentDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlDatePicker',{attrs:{"label":_vm.$t('ViewOnOrder.ColShipmentDate'),"is-invalid":invalid},model:{value:(_vm.newItem.shipmentDate),callback:function ($$v) {_vm.$set(_vm.newItem, "shipmentDate", $$v)},expression:"newItem.shipmentDate"}})]}}])}),_c('SlInput',{staticClass:"create-order__item",attrs:{"label":_vm.$t('ViewOnOrder.DocNumber')},model:{value:(_vm.newItem.orderNumber),callback:function ($$v) {_vm.$set(_vm.newItem, "orderNumber", $$v)},expression:"newItem.orderNumber"}}),(_vm.isArrival)?[_c('SlInput',{staticClass:"create-order__item",attrs:{"label":_vm.$t('Main.Cols.SourceFrom')},model:{value:(_vm.newItem.sourceFrom),callback:function ($$v) {_vm.$set(_vm.newItem, "sourceFrom", $$v)},expression:"newItem.sourceFrom"}}),_c('SlValidate',{staticClass:"create-order__item",attrs:{"rules":"sl_valid_date|sl_date_between","vid":"placeDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlDatePicker',{attrs:{"label":_vm.$t('ViewOnOrder.ColPlaceDate'),"is-invalid":invalid},model:{value:(_vm.newItem.placeDate),callback:function ($$v) {_vm.$set(_vm.newItem, "placeDate", $$v)},expression:"newItem.placeDate"}})]}}],null,false,4293890684)})]:_vm._e(),(_vm.isArrival)?_c('div',{staticClass:"create-order__item create-order__item--last"},[_c('SlInput',{attrs:{"label":_vm.$t('ViewOnOrder.ColLotCost'),"type":"number","float":""},model:{value:(_vm.newItem.cost),callback:function ($$v) {_vm.$set(_vm.newItem, "cost", $$v)},expression:"newItem.cost"}}),_c('SlInput',{staticClass:"w-72",attrs:{"label":_vm.$t('ViewOnOrder.ColCurrency'),"max-length":"4"},model:{value:(_vm.newItem.currency),callback:function ($$v) {_vm.$set(_vm.newItem, "currency", $$v)},expression:"newItem.currency"}})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }