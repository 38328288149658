<template>
  <SubPageWrapper
    data-shortcut-table
    width="full"
  >
    <CreateOrderModal :id="modalIds.CREATE_IN_TRANSITION_ORDER" />

    <template #loader>
      <SlOverlay :show="ordersUpdating" />
    </template>
    <template #left-col>
      <SlTabList
        v-model="tabModel"
        :tabs="tabs"
        collapsable
      />
    </template>
    <template #breadcrumbs>
      <SlBreadcrumb @click="handleBack">
        {{ $t(sourceData.locale) }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('ViewOnOrder.Ui.:Caption') }}
      </SlBreadcrumb>
    </template>
    <template #filter>
      <SlBadge
        v-if="activeFilterId !== DEFAULT_FILTER_ID"
        :close-tooltip="getTooltip($t('Web.Filters.RemoveFilter'))"
        closable
        @close="handleCloseFilter"
      >
        <template #text>
          <span class="subpage__filter-name">
            {{ $t('Web.Filters.FilteredByNew') }}
          </span>
          <span>
            {{ activeFilterName }}
          </span>
        </template>
      </SlBadge>
    </template>
    <template #actions>
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="!isEdited"
        class="grey-80"
        @click="handleDiscard"
      >
        {{ $t('Web.Collections.Discard') }}
      </SlButton>
      <SlButton
        :disabled="!isEdited"
        @click="handleSave"
      >
        {{ $t('Web.Collections.SaveChanges') }}
      </SlButton>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <div class="subpage__actions-group">
          <SlSearchInput
            :value="search"
            class="w-280"
            @submit="handleSearch"
          />
        </div>
        <SlSelectionInfo
          v-if="isActionsVisible"
          @shortcut:delete="handleDelete"
        >
          <template
            v-if="selectedRowsCount"
            #description
          >
            {{ $t('Web.Selection', { 1: selectedRowsCount }) }}
          </template>
          <template #actions>
            <SlButton
              variant="text"
              color="grey"
              @click="resetSelected"
            >
              <template #prepend>
                <icon
                  class="fill-off size-20 color-grey-80"
                  data="@icons/close.svg"
                />
              </template>
              {{ $t('Web.Deselect') }}
            </SlButton>
            <SlButton
              variant="primary"
              color="accent-red"
              :disabled="!$sl_tabEditable"
              @click="handleDelete"
            >
              <template #prepend>
                <icon
                  data="@icons/trash.svg"
                  class="fill-off size-16 color-white-stroke"
                />
              </template>
              {{ $t('EditCollections.Ui.butRemoveContainer') }}
            </SlButton>
          </template>
        </SlSelectionInfo>
        <div
          v-else
          class="subpage__actions-group"
        >
          <SlButton
            variant="secondary"
            color="grey"
            :disabled="!$sl_tabEditable"
            @click="showCreateModal"
          >
            <template #prepend>
              <icon
                data="@icons/plus.svg"
                class="fill-off size-16 color-grey-80"
              />
            </template>
            {{ $t('Web.InTransition.BtnCreateOrder') }}
          </SlButton>
          <SlDropdown
            :target-label="$t('Main.CustomizeColumnsMenu')"
            placement="bottom-end"
          >
            <template #prepend-inner>
              <icon
                data="@icons/table.svg"
                class="size-16 fill-off"
              />
            </template>
            <template #content>
              <SlDropdownOption
                v-for="({ key, name }) in showColumnsOptions"
                :key="key"
              >
                <template #prepend>
                  <SlCheckbox
                    :id="key"
                    :value="isVisibleCol(key)"
                    @change="(checked) => toggleVisibleCols(key, checked)"
                  />
                </template>
                <label :for="key">
                  {{ name }}
                </label>
              </SlDropdownOption>
            </template>
          </SlDropdown>
          <SlDropdown
            :target-label="$t('Web.InTransition.BtnExportImport')"
            placement="bottom-end"
          >
            <template #content>
              <SlDropdownOption
                select-close
                @select="handleExportToXlsx"
              >
                {{ $t('Main.Ui.acChooseExportToXlsx') }}
              </SlDropdownOption>
              <SlDropdownOption
                :disabled="!$sl_tabEditable"
                select-close
                @select="handleImportFromXlsx"
              >
                {{ $t('Web.InTransition.ImportFromXLSX') }}
              </SlDropdownOption>
            </template>
          </SlDropdown>
        </div>
      </div>
      <div class="subpage__table-wrapper">
        <SlTabContent
          v-for="tab in tabs"
          :key="tab.value"
          :value="tab.value"
          :tab-value="tabModel"
        >
          <div class="subpage__table">
            <SlTable
              :id="tab.value"
              :ref="tab.value"
              :headers="tableHeaders"
              :rows="tableRows"
              unique-key="id"
              header-unique-key="key"
              header-name-key="name"
              header-type-key="type"
              :hidden-columns-keys="['id', checkboxKey]"
              :max-height="1000"
              :value-parser="parseValue"
              api-sortable
              :sort-by="colClass"
              :sort-order="sortOrder"
              selectable
              pagination
              :total-rows-count="totalRowsCount"
              :current-page="currentPage"
              :per-page="perPage"
              :loading="loading"
              :inner-no-data="!!search"
              num-col
              highlight-row
              @page-change="handlePaginationChange"
              @per-page-change="handlePerPageChange"
              @sort="handleSort"
              @contextmenu:clipboard="handleContextMenu"
            >
              <template #loader>
                <SlOverlay :show="loading" />
              </template>

              <template #header-checkbox>
                <SlCheckbox
                  :value="headerCheckboxValue"
                  :indeterminate="isIndeterminate"
                  @change="handleSelectRowsAll"
                />
              </template>

              <template #row-checkbox="{ rowId, row }">
                <SlCheckbox
                  v-tooltip="getRowCheckboxTooltip(row)"
                  :value="isRowSelected(row)"
                  :disabled="isRowCheckboxDisabled(row)"
                  @change="(val) => handleSelectRows(val, rowId)"
                />
              </template>

              <template
                v-if="$sl_tabEditable"
                #arrivalDate="slotScope"
              >
                <SlTableDatepicker
                  v-if="isArrival"
                  :value="getInputValue(slotScope)"
                  :text-value="getDateTextValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  :vid="`arrivalDate-${slotScope.rowIndex}`"
                  @input="(val) => handleCellInput({ slotScope, val })"
                  @submit="() => handleCellSubmit({
                    slotScope,
                    val: slotScope.cellValue.val
                  })"
                />
              </template>
              <template #orderType="slotScope">
                <template v-if="$sl_tabEditable">
                  <SlTableSelect
                    v-if="isArrival"
                    :foreground="getInputForeground(slotScope)"
                    :value="getInputValue(slotScope)"
                    :options="orderTypeOptions"
                    label="text"
                    track-by="value"
                    type="row"
                    :return-object="false"
                    editable-bg
                    @input="(val) => handleCellSubmit({ slotScope, val })"
                  />
                </template>
                <div
                  v-else
                  class="sl-table__cell-text"
                >
                  {{ getOrderTypeValue(slotScope) }}
                </div>
              </template>
              <template
                v-if="$sl_tabEditable"
                #sourceFrom="slotScope"
              >
                <SlTableInput
                  v-if="isArrival"
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="100"
                  type="text"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template
                v-if="$sl_tabEditable"
                #placeDate="slotScope"
              >
                <SlTableDatepicker
                  v-if="isArrival"
                  :value="getInputValue(slotScope)"
                  :text-value="getDateTextValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  :vid="`placeDate-${slotScope.rowIndex}`"
                  @input="(val) => handleCellInput({ slotScope, val})"
                  @submit="() => handleCellSubmit({
                    slotScope,
                    val: slotScope.cellValue.val
                  })"
                />
              </template>
              <template
                v-if="$sl_tabEditable"
                #cost="slotScope"
              >
                <SlTableInput
                  v-if="isArrival"
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  :input-regexp="regExp.floatInput"
                  :submit-regexp="regExp.floatSubmit"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template
                v-if="$sl_tabEditable"
                #currency="slotScope"
              >
                <SlTableInput
                  v-if="isArrival"
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  type="text"
                  :max-length="currencyLength"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>

              <template
                v-if="$sl_tabEditable"
                #shipmentDate="slotScope"
              >
                <SlTableDatepicker
                  v-if="!isArrival"
                  :value="getInputValue(slotScope)"
                  :text-value="getDateTextValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  :vid="`shipmentDate-${slotScope.rowIndex}`"
                  @input="(val) => handleCellInput({ slotScope, val })"
                  @submit="() => handleCellSubmit({
                    slotScope,
                    val: slotScope.cellValue.val
                  })"
                />
              </template>

              <template
                v-if="$sl_tabEditable"
                #qty="slotScope"
              >
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template
                v-if="$sl_tabEditable"
                #orderNumber="slotScope"
              >
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="100"
                  type="text"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #partOfForecast="slotScope">
                <template v-if="$sl_tabEditable">
                  <SlTableSelect
                    :foreground="getInputForeground(slotScope)"
                    :value="getInputValue(slotScope)"
                    :options="partOfForecastOptions"
                    label="text"
                    track-by="value"
                    type="row"
                    :return-object="false"
                    editable-bg
                    @input="(val) => handleCellSubmit({ slotScope, val })"
                  />
                </template>
                <div
                  v-else
                  class="sl-table__cell-text"
                >
                  {{ getPartOfForecastValue(slotScope) }}
                </div>
              </template>
              <template
                v-for="infoField in infoFieldKeys"
                v-slot:[infoField]="slotScope"
              >
                <SlTableInput
                  v-if="$sl_tabEditable"
                  :key="infoField"
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="100"
                  type="text"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #creationType="slotScope">
                <div class="sl-table__cell-text">
                  {{ getCreationTypeValue(slotScope) }}
                </div>
              </template>

              <template #no-data>
                <SlNoData>
                  <template #image>
                    <icon
                      :data="noDataMeta.icon"
                      :class="{
                        'illustration-md': !search,
                        'size-48': search,
                        'fill-off': search
                      }"
                    />
                  </template>
                  <template #title>
                    {{ noDataMeta.title }}
                  </template>
                  <template #text>
                    {{ noDataMeta.text }}
                  </template>
                </SlNoData>
              </template>
            </SlTable>
          </div>
        </SlTabContent>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CreateOrderModal from '@/components/Modals/InTransition/CreateOrderModal';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import { access } from '@/mixins/access';
import { tableConfig } from '@/mixins/tableConfig';
import { shortcut } from '@/mixins/shortcut';
import { exportTypes } from '@/config/report/inventoryReport';
import modalIds from '@/config/shared/modalsId.config';
import {
  inTransitionConfig,
  inTransitionCreationTypes,
  inTransitionInfoField,
  inTransitionKeys,
  inTransitionLabelCodeByType,
  orderTypeOptions,
  orderTypes,
  requestTypeByKey,
  sourceByRoute,
  partOfForecastOptions,
  partOfForecastTypes
} from '@/config/report/inventoryReport/inTransition.config';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import modalsId from '@/config/shared/modalsId.config';
import statusCodes from '@/config/utils/statusCodes.config';
import { checkboxStates } from '@/config/shared/slTable.config';
import { namespaceByRoute } from '@/config/report';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { getTooltip } from '@/helpers/shared/tooltip';
import { toArray } from '@/helpers/utils/toArray';
import regExp from '@/helpers/utils/regExp';

export default {
  name: 'InTransition',
  components: {
    CreateOrderModal
  },
  mixins: [modal, fileSaver, access, tableConfig, shortcut],
  data() {
    return {
      DEFAULT_FILTER_ID,
      regExp,
      exportTypes,
      modalIds,
      isRedirect: false,
      loading: false,
      ordersUpdating: false,
      creationTypeMap: {
        [inTransitionCreationTypes.IMPORT]: this.$t('Main.CreationType.Import'),
        [inTransitionCreationTypes.CUSTOM]: this.$t('Main.CreationType.Custom')
      },
      orderTypeMap: {
        [orderTypes.NONE]: '',
        [orderTypes.PURCHASE]: this.$t('Main.OrderType.Purchase'),
        [orderTypes.MANUFACTURE]: this.$t('Main.OrderType.Manufacture'),
        [orderTypes.TRANSFER]: this.$t('Main.OrderType.Transfer')
      },
      partOfForecastMap: {
        [partOfForecastTypes.YES]: this.$t('Common.Yes'),
        [partOfForecastTypes.NO]: this.$t('Common.No')
      },
      checkboxKey: 'enabled',
      getTooltip,
      partOfForecastOptions: partOfForecastOptions(this),
      orderTypeOptions: orderTypeOptions(this),
      currencyLength: 4,
      needRefetch: false
    };
  },
  computed: {
    ...mapState({
      [inTransitionKeys.ARRIVAL]: state => state.inventoryReport.inTransition[inTransitionKeys.ARRIVAL],
      [inTransitionKeys.BACKORDER]: state => state.inventoryReport.inTransition[inTransitionKeys.BACKORDER],
      tab: state => state.inventoryReport.inTransition.tab
    }),
    ...mapGetters({
      getCreateData: 'inventoryReport/inTransition/getCreateData',
      isEdited: 'inventoryReport/inTransition/isEdited'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    activeFilterId() {
      return this.$store.state[this.reportNamespace]?.active_filter_id;
    },
    activeFilter() {
      return this.$store.getters[`${this.reportNamespace}/activeFilter`];
    },
    activeFilterName() {
      return this.activeFilter?.name || '';
    },
    tabModel: {
      get() {
        return this.tab;
      },
      set(value) {
        this.setTab(value);
      }
    },
    tableConfig() {
      return this[this.tabModel].tableConfig;
    },
    title() {
      return this.$t(inTransitionLabelCodeByType[this.tabModel]);
    },
    tabs() {
      return inTransitionConfig(this);
    },
    table() {
      return this[this.tabModel].table || {};
    },
    tableHeaders() {
      return toArray(this.table.headers);
    },
    tableRows() {
      return toArray(this.table.rows);
    },
    showColumnsOptions() {
      return this.table.metadata?.availableCols?.filter(header => header.key !== this.checkboxKey) || [];
    },
    totalRowsCount() {
      return this.table.totalRowsCount;
    },
    currentPage() {
      return this.table.currentPage;
    },
    isArrival() {
      return this.tabModel === inTransitionKeys.ARRIVAL;
    },
    sourceData() {
      return sourceByRoute[this.$sl_routeName];
    },
    checkboxHeader() {
      return this.table.metadata?.checkbox;
    },
    selectedRowsCount() {
      return this.table.metadata?.selectedRowsCount;
    },
    isIndeterminate() {
      return this.checkboxHeader === checkboxStates.MIXED;
    },
    headerCheckboxValue() {
      return this.checkboxHeader !== checkboxStates.UNCHECKED;
    },
    isActionsVisible() {
      return !this.loading
        && (this.headerCheckboxValue || this.selectedRowsCount > 0)
        && this.tableRows.length;
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle'),
        text: this.$t('Web.NoData.PressToAddText', {
          1: this.$t('Web.InTransition.BtnCreateOrder')
        })
      };
    },
    infoFieldKeys() {
      return this.table.headers?.map(header => {
        if (header.key.includes(inTransitionInfoField)) {
          return header.key;
        }
      });
    }
  },
  watch: {
    tabModel() {
      if (!this.tableHeaders.length || this.needRefetch) {
        this.fetchTables();

        this.needRefetch = false;
      }
    },
    isEdited(val) {
      preventTabClose(val);
    }
  },
  async beforeMount() {
    await this.fetchTables();
  },
  beforeDestroy() {
    preventTabClose();
  },
  destroyed() {
    this.resetState();
  },
  beforeRouteLeave(_, __, next) {
    if (!this.isRedirect) {
      return this.onRouteLeave(next);
    }

    next();
  },
  methods: {
    ...mapActions({
      fetchInTransition: 'inventoryReport/inTransition/fetchInTransition',
      exportToXlsx: 'inventoryReport/inTransition/exportToXlsx',
      selectRow: 'inventoryReport/inTransition/selectRow',
      updateCell: 'inventoryReport/inTransition/updateCell',
      createOrder: 'inventoryReport/inTransition/createOrder',
      deleteOrders: 'inventoryReport/inTransition/deleteOrders',
      saveOverrides: 'inventoryReport/inTransition/saveOverrides',
      discardOverrides: 'inventoryReport/inTransition/discardOverrides',
      updateOverrides: 'inventoryReport/inTransition/updateOverrides',
      resetState: 'inventoryReport/inTransition/resetState',
      setTab: 'inventoryReport/inTransition/setTab',
      updateTableConfig: 'inventoryReport/inTransition/updateTableConfig',
      importFile: 'inventoryReport/inTransition/importFromXlsx',
      updateVisibleColumns: 'inventoryReport/inTransition/updateVisibleColumns'
    }),
    async fetchTables() {
      try {
        this.loading = true;

        await this.fetchInTransition();
      } finally {
        this.loading = false;
      }
    },
    // route leave
    handleBack() {
      this.$router.push({
        name: this.sourceData.route
      });
    },
    onRouteLeave(next) {
      if (!this.isEdited) {
        return next();
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleUnsavedChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextUnsavedChanges'),
        icon: 'style_save_double',
        discardText: this.$t('Web.Modals.UnsavedConfirm.ButtonExitDiscard'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonSave'),
        discardCallback: async() => {
          await this.discardOverrides();

          this.isRedirect = true;

          next();
        },
        confirmCallback: async() => {
          await this.handleSave();

          this.isRedirect = true;

          next();
        }
      });
    },
    //filters
    async handleCloseFilter() {
      this.needRefetch = true;

      await this.$store.dispatch(`${this.reportNamespace}/setActiveFilterId`, DEFAULT_FILTER_ID);

      this.fetchTables();
    },
    // search
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchTables();
    },
    // export/import
    handleExportToXlsx() {
      this.saveFile(this.exportToXlsx);
    },
    handleImportFromXlsx() {
      if (!this.isEdited) {
        return this.showImportFileModal();
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
        icon: 'style_save_double',
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonDiscard'),
        confirmCallback: async() => {
          await this.discardOverrides();
          this.showImportFileModal();
        }
      });
    },
    showImportFileModal() {
      this.showModal(modalIds.SL_UPLOAD_FILE, {
        title: this.$t('Web.InTransition.ImportFile'),
        uploadHandler: this.importFile,
        errorsHandler: this.handleOverridesError
      });
    },
    // create/delete
    showCreateModal() {
      this.showModal(modalsId.CREATE_IN_TRANSITION_ORDER, {
        inputData: this.getCreateData(this.tabModel),
        type: this.tabModel,
        createCallback: this.handleCreateItem
      });
    },
    handleDelete() {
      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.Delete.Title', {
          1: this.$t('Web.Pluralization.Item.val', {
            p1: this.$tc('Web.Pluralization.Item.p1', this.selectedRowsCount, { 1: this.selectedRowsCount })
          })
        }),
        text: this.$t('Web.Modals.Delete.DescriptionBulk', {
          1: this.$tc('Web.Pluralization.ItThem', this.selectedRowsCount)
        }),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: async() => {
          await this.deleteOrders();

          this.resetSelected();
        }
      });
    },
    async handleCreateItem(item) {
      await this.createOrder(item);

      this.resetSelected();
    },
    // row selection
    isRowSelected(row) {
      return row[this.checkboxKey]?.val;
    },
    isRowCheckboxDisabled({ creationType }) {
      return creationType?.val !== inTransitionCreationTypes.CUSTOM;
    },
    resetSelected() {
      this.$refs[this.tabModel][0]?.resetSelected();

      this.updateOverrides({
        [this.checkboxKey]: false,
        filterId: this.activeFilterId
      });
    },
    handleSelectRowsAll(checked) {
      this.updateOverrides({
        [this.checkboxKey]: checked,
        filterId: this.activeFilterId
      });
    },
    handleSelectRows(checked, id) {
      this.selectRow({
        value: checked,
        rowId: id,
        cellKey: this.checkboxKey
      });

      this.updateOverrides({
        id,
        [this.checkboxKey]: checked
      });
    },
    // table configuration
    isVisibleCol(colKey) {
      return Boolean(this.tableHeaders.find(header => header.key === colKey));
    },
    toggleVisibleCols(key, state) {
      this.updateVisibleColumns({
        state,
        class: key
      });
    },
    getOrderTypeValue(scope) {
      return this.orderTypeMap[scope.cellValue.val];
    },
    getPartOfForecastValue(scope) {
      return this.partOfForecastMap[scope.cellValue.val];
    },
    getCreationTypeValue(scope) {
      return this.creationTypeMap[scope.cellValue.val];
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || cellValue.auto || '';
    },
    getInputValue(scope) {
      return this.checkCellValue(scope.cellValue?.val) ?? this.checkCellValue(scope.cellValue?.auto) ?? '';
    },
    getDateTextValue(scope) {
      const override = scope.cellValue?.val ?? '';
      const auto = scope.cellValue?.auto ?? '';
      const getValueText = (value) => {
        const isUnknown = value?.includes('/');

        if (isUnknown) {
          return this.$t('ViewOnOrder.Date.Unknown');
        }

        return dateByLocaleKey(value, localeDateKeys.YMD);
      };

      if (override) {
        return getValueText(override);
      }

      if (auto) {
        return getValueText(auto);
      }

      return '';
    },
    getInputForeground(scope) {
      const val = scope.cellValue?.val;

      if (val || val === 0) {
        return 'over';
      }

      return '';
    },
    handlePaginationChange(page) {
      this.page = page;

      this.fetchTables();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchTables();
    },
    async handleSort({ callback, isResort }) {
      const { key, order } = callback();

      this.oldSortOrder = this.sortOrder;
      this.oldCollClass = this.colClass;

      this.sortOrder = order;
      this.colClass = key;

      try {
        this.loading = true;

        await this.setSortingParams({
          config: this.tableConfig,
          params: {
            table: requestTypeByKey[this.tabModel]
          }
        });
      } catch (e) {
        this.sortOrder = this.oldSortOrder;
        this.colClass = this.oldCollClass;

        if (isResort || e.status !== statusCodes.FORBIDDEN) {
          return;
        }

        this.showModal(modalIds.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.UnsavedConfirm.TitleUnsavedChanges'),
          text: this.$t('Web.Modals.UnsavedChangesSort'),
          icon: 'style_warning',
          discardText: this.$t('Web.Modals.UnsavedConfirm.ButtonExitDiscard'),
          confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonSave'),
          discardCallback: async() => {
            await this.discardOverrides();

            this.handleSort({
              callback,
              isResort: true
            });
          },
          confirmCallback: async() => {
            await this.handleSave();

            this.handleSort({
              callback,
              isResort: true
            });
          }
        });
      } finally {
        this.loading = false;
      }
    },
    formatCellValue({ slotScope, val }) {
      if (val === null) {
        return val;
      }

      if (slotScope.cellKey === 'currency') {
        return val.slice(0, this.currencyLength);
      }

      return ['qty', 'cost'].includes(slotScope.cellKey) ? +val : val;
    },
    handleCellInput({ slotScope, val }) {
      this.updateCell({
        value: val,
        rowId: slotScope.rowId,
        cellKey: slotScope.cellKey,
        cellIndex: slotScope.cellIndex
      });
    },
    handleCellSubmit({ slotScope, val }) {
      this.updateOverrides({
        id: slotScope.rowId,
        [slotScope.cellKey]: this.formatCellValue({ slotScope, val })
      });
    },
    parseErrorsData(errorBody) {
      if (!errorBody?.metainfo) {
        return {
          text: errorBody?.message || this.$t('Web.Error.Default')
        };
      }

      return {
        data: [
          ...toArray(errorBody.metainfo?.critical),
          ...toArray(errorBody.metainfo?.nonCritical)
        ]
      };
    },
    handleOverridesError(e) {
      const { text, data } = this.parseErrorsData(e);

      if (text && !data) {
        return this.$notify({
          type: 'error',
          text
        });
      }

      this.$notify({
        type: 'warn',
        title: this.$t('Web.InTransition.ErrorSaveTitle'),
        duration: -1,
        data: {
          callback: () => this.showModal(modalIds.ERRORS_NOTIFY, {
            title: this.$t('Web.InTransition.ErrorSaveTitle'),
            errorsData: data
          })
        }
      });

      this.hideModal(modalIds.SL_UPLOAD_FILE);
    },
    // actions
    handleDiscard() {
      if (this.isEdited) {
        this.showModal(modalIds.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
          text: this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
          icon: 'style_save_double',
          confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonDiscard'),
          confirmCallback: this.discardOverrides
        });
      }
    },
    async handleSave() {
      try {
        this.ordersUpdating = true;

        await this.saveOverrides();

        this.$notify({
          type: 'success',
          title: this.$t('Common.Save'),
          text: this.$t('Web.Notification.TitleSavedSuccess')
        });
      } catch (e) {
        this.handleOverridesError(e);
      } finally {
        this.ordersUpdating = false;
      }
    },
    checkCellValue(value) {
      return (value || value === 0) ? value : null;
    },
    getRowCheckboxTooltip(row) {
      return this.isRowCheckboxDisabled(row) ? getTooltip(this.$t('Web.InTransition.TooltipRowCheckboxDisabled')) : '';
    },
    handleContextMenu(callback) {
      callback({ updateCallback: this.handleCellSubmit });
    }
  }
};
</script>

<style lang="scss" scoped>
.subpage__table-wrapper {
  height: calc(100% - 52px);
}
</style>
