<template>
  <SlModal
    :id="id"
    :title="modalTitle"
    persistent
    @created="onCreated"
    @on-enter="handleCreate"
    @hide="onModalClose"
    @show="onModalShow"
  >
    <template
      v-if="errorMessage"
      #alert
    >
      <SlAlert
        type="error"
        :accent="errorMessage"
      />
    </template>

    <ValidationObserver
      ref="observer"
      class="modal-content create-order"
    >
      <SlValidate
        v-slot="{ invalid }"
        rules="required"
        vid="itemCode"
        class="create-order__item"
      >
        <SlSelect
          :value="newItem.itemcode"
          :select-label="$t('Main.Cols.ItemCode')"
          :options="itemCodes"
          :loading="isLoading"
          :internal-search="false"
          :is-invalid="invalid"
          searchable
          required
          @search-change="(val) => handleSearch(val, inTransitionEntities.ITEM)"
          @input="(val) => handleSelectChange(val, inTransitionEntities.ITEM)"
        />
      </SlValidate>
      <SlValidate
        v-if="hasLocation"
        v-slot="{ invalid }"
        :rules="{
          required: !isLocationDisabled
        }"
        vid="location"
        class="create-order__item"
      >
        <div v-tooltip.bottom="locationTooltip">
          <SlSelect
            :value="newItem.location"
            :select-label="$t('Main.Cols.Location')"
            :options="locations"
            :loading="isLoading"
            :internal-search="false"
            :is-invalid="invalid"
            :disabled="isLocationDisabled"
            required
            searchable
            @search-change="(val) => handleSearch(val, inTransitionEntities.LOCATION)"
            @input="(val) => handleSelectChange(val, inTransitionEntities.LOCATION)"
          />
        </div>
      </SlValidate>
      <div
        v-if="!isArrival && hasChannel"
        class="create-order__item"
      >
        <SlSelect
          v-tooltip.bottom="channelTooltip"
          :value="newItem.channel"
          :select-label="$t('Main.Cols.Channel')"
          :options="channels"
          :loading="isLoading"
          :internal-search="false"
          :disabled="isChannelDisabled"
          searchable
          @search-change="(val) => handleSearch(val, inTransitionEntities.CHANNEL)"
          @input="(val) => handleSelectChange(val, inTransitionEntities.CHANNEL)"
        />
      </div>
      <SlValidate
        v-slot="{ invalid }"
        rules="required|max_value:9999999999"
        vid="qty"
        class="create-order__item"
      >
        <SlInput
          v-model="newItem.qty"
          :label="$t('Main.Cols.OrderBy0')"
          type="number"
          :is-invalid="invalid"
          required
        />
      </SlValidate>
      <SlValidate
        v-if="isArrival"
        v-slot="{ invalid }"
        rules="sl_valid_date|sl_date_between"
        vid="arrivalDate"
        class="create-order__item"
      >
        <SlDatePicker
          v-model="newItem.arrivalDate"
          :label="$t('PlannedOrders.Col.ArrivalDate')"
          :is-invalid="invalid"
        />
      </SlValidate>
      <SlSelect
        v-if="isArrival"
        v-model="newItem.orderType"
        :select-label="$t('Main.Cols.OrderType')"
        :options="orderTypeOptions"
        track-by="value"
        label="text"
        class="create-order__item"
        allow-empty
        clearable
        @input="(val) => handleSelectChange(val, 'orderType')"
      />
      <SlValidate
        v-else
        v-slot="{ invalid }"
        rules="sl_valid_date|sl_date_between"
        vid="shipmentDate"
        class="create-order__item"
      >
        <SlDatePicker
          v-model="newItem.shipmentDate"
          :label="$t('ViewOnOrder.ColShipmentDate')"
          :is-invalid="invalid"
        />
      </SlValidate>
      <SlInput
        v-model="newItem.orderNumber"
        :label="$t('ViewOnOrder.DocNumber')"
        class="create-order__item"
      />
      <template v-if="isArrival">
        <SlInput
          v-model="newItem.sourceFrom"
          :label="$t('Main.Cols.SourceFrom')"
          class="create-order__item"
        />
        <SlValidate
          v-slot="{ invalid }"
          rules="sl_valid_date|sl_date_between"
          vid="placeDate"
          class="create-order__item"
        >
          <SlDatePicker
            v-model="newItem.placeDate"
            :label="$t('ViewOnOrder.ColPlaceDate')"
            :is-invalid="invalid"
          />
        </SlValidate>
      </template>
      <div
        v-if="isArrival"
        class="create-order__item create-order__item--last"
      >
        <SlInput
          v-model="newItem.cost"
          :label="$t('ViewOnOrder.ColLotCost')"
          type="number"
          float
        />
        <SlInput
          v-model="newItem.currency"
          :label="$t('ViewOnOrder.ColCurrency')"
          class="w-72"
          max-length="4"
        />
      </div>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleClose"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          @click="handleCreate"
        >
          {{ $t('Web.InTransition.BtnCreate') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import {
  inTransitionEntities,
  inTransitionKeys,
  orderTypeOptions
} from '@/config/report/inventoryReport/inTransition.config';
import { EMPTY_CACHE_OVERRIDE } from '@/config/shared/magicValues.config';
import { customDebounce } from '@/helpers/shared/listeners';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'CreateOrderModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      inTransitionEntities,
      inputData: [],
      type: '',
      createCallback: null,
      newItem: {},
      errorMessage: '',
      isLoading: false,
      [inTransitionEntities.ITEM]: {
        action: this.fetchItemCodes,
        search: ''
      },
      [inTransitionEntities.LOCATION]: {
        action: this.fetchLocations,
        search: ''
      },
      [inTransitionEntities.CHANNEL]: {
        action: this.fetchChannels,
        search: ''
      },
      getTooltip
    };
  },
  computed: {
    ...mapState({
      itemCodes: state => state.project.item_codes,
      locations: state => state.project.locations,
      channels: state => state.project.channels
    }),
    ...mapGetters({
      hasLocation: 'project/hasLocation',
      hasChannel: 'project/hasChannel'
    }),
    modalTitle() {
      if (this.isArrival) {
        return this.$t('Web.InTransition.CreateArrival');
      }

      return this.$t('Web.InTransition.CreateBackorder');
    },
    isArrival() {
      return this.type === inTransitionKeys.ARRIVAL;
    },
    orderTypeOptions() {
      return orderTypeOptions(this).slice(1);
    },
    isLocationDisabled() {
      return !this.newItem.itemcode
        || (!!this.newItem.itemcode && !this.locations.length && !this[inTransitionEntities.LOCATION].search);
    },
    isChannelDisabled() {
      if (this.hasLocation) {
        return !this.newItem.location
          || (!!this.newItem.location && !this.channels.length && !this[inTransitionEntities.CHANNEL].search);
      }

      return !this.newItem.itemcode || (!!this.newItem.itemcode && !this.channels.length && !this[inTransitionEntities.CHANNEL].search);
    },
    locationTooltip() {
      return this.getTooltip({
        content: !this.newItem.itemcode
          ? this.$t('Web.InTransition.TooltipLocation')
          : this.$t('Web.InTransition.TooltipNoLocation'),
        disabled: !!this.newItem.itemcode && !!this.locations.length
      });
    },
    channelTooltip() {
      let content = '';

      if (!this.newItem.location && this.hasLocation) {
        content = this.$t('Web.InTransition.TooltipChannel');
      } else if (!this.newItem.itemcode && !this.hasLocation) {
        content = this.$t('Web.InTransition.TooltipNoItem');
      } else if (this.newItem.itemcode && !this.hasLocation && !this.channels.length) {
        content = this.$t('Web.InTransition.TooltipNoChannelNoLoc');
      } else if (this.newItem.itemcode && this.newItem.location && !this.channels.length) {
        content = this.$t('Web.InTransition.TooltipNoChannel');
      }

      return this.getTooltip({
        content,
        disabled: this.hasLocation
          ? !!this.newItem.location && !!this.channels.length
          : !!this.channels.length
      });
    }
  },
  methods: {
    ...mapActions({
      fetchItemCodes: 'project/fetchItemCodes',
      fetchLocations: 'project/fetchLocations',
      fetchChannels: 'project/fetchChannels',
      setItemCodes: 'project/setItemCodes',
      setLocations: 'project/setLocations',
      setChannels: 'project/setChannels'
    }),
    onModalShow() {
      Object.keys(this.inputData).forEach(key => {
        this.$set(this.newItem, key, '');
      });
    },
    onModalClose() {
      this.inputData = [];
      this.type = '';
      this.createCallback = null;
      this.newItem = {};
      this.errorMessage = '';

      this.$set(this[inTransitionEntities.ITEM], 'search', '');
      this.$set(this[inTransitionEntities.LOCATION], 'search', '');
      this.$set(this[inTransitionEntities.CHANNEL], 'search', '');

      this.setItemCodes([]);
      this.setLocations([]);
      this.setChannels([]);
    },
    resetValueByEntity(entity) {
      if (entity === inTransitionEntities.ITEM) {
        this.$set(this.newItem, inTransitionEntities.ITEM, '');
        this.$set(this.newItem, inTransitionEntities.LOCATION, '');
        this.$set(this.newItem, inTransitionEntities.CHANNEL, '');

        return;
      }

      if (entity === inTransitionEntities.LOCATION) {
        this.$set(this.newItem, inTransitionEntities.LOCATION, '');
        this.$set(this.newItem, inTransitionEntities.CHANNEL, '');

        return;
      }

      this.$set(this.newItem, inTransitionEntities.CHANNEL, '');
    },
    fetchEntities({ entity, ...params }) {
      return this[entity].action(params);
    },
    searchEntities: customDebounce(async function(query, entity) {
      try {
        this.$set(this[entity], 'search', query);

        await this.fetchEntities({
          entity,
          query,
          item: this.newItem[inTransitionEntities.ITEM],
          location: this.newItem[inTransitionEntities.LOCATION]
        });
      } finally {
        this.isLoading = false;
      }
    }, 200),
    async handleSearch(query, entity) {
      if (!query) {
        return;
      }

      this.resetValueByEntity(entity);

      this.isLoading = true;

      this.searchEntities(query, entity);
    },
    handleSelectChange(value, key) {
      const newValue = value === EMPTY_CACHE_OVERRIDE ? '' : value;

      if (this[key]?.search !== undefined) {
        this.$set(this[key], 'search', '');
      }

      this.$set(this.newItem, key, newValue);

      if (key === inTransitionEntities.ITEM) {
        this.$set(this.newItem, inTransitionEntities.LOCATION, '');
        this.$set(this.newItem, inTransitionEntities.CHANNEL, '');

        const entity = this.hasLocation
          ? inTransitionEntities.LOCATION
          : inTransitionEntities.CHANNEL;

        return this.fetchEntities({
          entity,
          item: this.newItem[inTransitionEntities.ITEM],
          location: this.newItem[inTransitionEntities.LOCATION]
        });
      }

      if (key === inTransitionEntities.LOCATION && this.hasChannel) {
        this.$set(this.newItem, inTransitionEntities.CHANNEL, '');

        return this.fetchEntities({
          entity: inTransitionEntities.CHANNEL,
          item: this.newItem[inTransitionEntities.ITEM],
          location: this.newItem[inTransitionEntities.LOCATION]
        });
      }
    },
    async handleCreate() {
      if (!this.createCallback) {
        return;
      }

      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      try {
        await this.createCallback(this.newItem);

        this.hideModal(this.id);
      } catch (e) {
        this.errorMessage = e?.error?.message || '';
      }
    },
    handleClose() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/in-transition/create-order-modal.scss";
</style>
